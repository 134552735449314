const locations = [
  //{text : 'England', value : 'E92000001' },
  //{text : 'Scotland', value : 'S92000003' },
  //{text : 'Northern Ireland', value : 'N92000001' },
  //{text : 'Wales', value : 'W92000004' },
  //{text : 'West Midlands Region', value : 'E12000005' },
  //{text : 'Yorkshire and The Humber', value : 'E12000003' },
  //{text : 'East Midlands', value : 'E12000004' },
  {text : 'London', value : 'E12000007' },
 // {text : 'South West', value : 'E12000009' },
  //{text : 'United Kingdom', value : 'K02000001' },
  //{text : 'South East', value : 'E12000008' },
  //{text : 'North East', value : 'E12000001' },
  //{text : 'East of England', value : 'E12000006' },
  {text : 'Inner London', value : 'E13000001' },
  {text : 'Outer London', value : 'E13000002' },
  //{text : 'North West', value : 'E12000002' },
  {text : 'South Yorkshire', value : 'E11000003' },
  {text : 'Merseyside', value : 'E11000002' },
  //-{text : 'West Midlands', value : 'E11000005' },
  {text : 'Tyne and Wear', value : 'E11000007' },
  //-{text : 'Greater Manchester', value : 'E11000001' },
  //-{text : 'West Yorkshire', value : 'E11000006' },
 //- {text : 'Somerset', value : 'E10000027' },
  //-{text : 'Staffordshire', value : 'E10000028' },
  //-{text : 'Oxfordshire', value : 'E10000025' },
  //-{text : 'Warwickshire', value : 'E10000031' },
  //-{text : 'West Sussex', value : 'E10000032' },
  //-{text : 'Worcestershire', value : 'E10000034' },
  //-{text : 'Nottinghamshire', value : 'E10000024' },
  //-{text : 'Norfolk', value : 'E10000020' },
  //-{text : 'Surrey', value : 'E10000030' },
  //-{text : 'Suffolk', value : 'E10000029' },
  //-{text : 'Gloucestershire', value : 'E10000013' },
  //-{text : 'Hampshire', value : 'E10000014' },
  //-{text : 'Devon', value : 'E10000008' },
  //-{text : 'Derbyshire', value : 'E10000007' },
  //-{text : 'Cumbria', value : 'E10000006' },
  //-{text : 'Lancashire', value : 'E10000017' },
  //-{text : 'Essex', value : 'E10000012' },
  //-{text : 'East Sussex', value : 'E10000011' },
  //-{text : 'Kent', value : 'E10000016' },
  //-{text : 'Hertfordshire', value : 'E10000015' },
  //-{text : 'Cambridgeshire', value : 'E10000003' },
  //-{text : 'North Yorkshire', value : 'E10000023' },
  //-{text : 'Leicestershire', value : 'E10000018' },
  //-{text : 'Lincolnshire', value : 'E10000019' },
  {text : 'Lewisham', value : 'E09000023' },
  {text : 'Wandsworth', value : 'E09000032' },
  {text : 'Brent', value : 'E09000005' },
  {text : 'Bromley', value : 'E09000006' },
  {text : 'Camden', value : 'E09000007' },
  {text : 'Greenwich', value : 'E09000011' },
  {text : 'Hackney', value : 'E09000012' },
  {text : 'Hammersmith and Fulham', value : 'E09000013' },
  {text : 'Havering', value : 'E09000016' },
  {text : 'Hillingdon', value : 'E09000017' },
  {text : 'Hounslow', value : 'E09000018' },
  {text : 'Islington', value : 'E09000019' },
  {text : 'Kensington and Chelsea', value : 'E09000020' },
  {text : 'Ealing', value : 'E09000009' },
  {text : 'Enfield', value : 'E09000010' },
  {text : 'Kingston upon Thames', value : 'E09000021' },
  {text : 'Lambeth', value : 'E09000022' },
  {text : 'Barking and Dagenham', value : 'E09000002' },
  {text : 'Barnet', value : 'E09000003' },
  {text : 'City of London', value : 'E09000001' },
  {text : 'City of Westminster', value : 'E09000033' },
  {text : 'Croydon', value : 'E09000008' },
  {text : 'Harrow', value : 'E09000015' },
  {text : 'Redbridge', value : 'E09000026' },
  {text : 'Richmond upon Thames', value : 'E09000027' },
  {text : 'Sutton', value : 'E09000029' },
  {text : 'Tower Hamlets', value : 'E09000030' },
  {text : 'Newham', value : 'E09000025' },
  {text : 'Southwark', value : 'E09000028' },
  {text : 'Bexley', value : 'E09000004' },
  {text : 'Haringey', value : 'E09000014' },
  {text : 'Waltham Forest', value : 'E09000031' },
  {text : 'Merton', value : 'E09000024' },
  {text : 'Leeds', value : 'E08000035' },
  {text : 'Liverpool', value : 'E08000012' },
  {text : 'Bolton', value : 'E08000001' },
  {text : 'Bradford', value : 'E08000032' },
  {text : 'Bury', value : 'E08000002' },
  {text : 'Calderdale', value : 'E08000033' },
  {text : 'Doncaster', value : 'E08000017' },
  {text : 'Dudley', value : 'E08000027' },
  {text : 'Kirklees', value : 'E08000034' },
  {text : 'Knowsley', value : 'E08000011' },
  {text : 'Barnsley', value : 'E08000016' },
  {text : 'Birmingham', value : 'E08000025' },
  {text : 'Coventry', value : 'E08000026' },
  {text : 'Rochdale', value : 'E08000005' },
  {text : 'Rotherham', value : 'E08000018' },
  {text : 'Gateshead', value : 'E08000037' },
  {text : 'Sunderland', value : 'E08000024' },
  {text : 'Tameside', value : 'E08000008' },
  {text : 'Newcastle upon Tyne', value : 'E08000021' },
  {text : 'Oldham', value : 'E08000004' },
  {text : 'Salford', value : 'E08000006' },
  {text : 'Sandwell', value : 'E08000028' },
  {text : 'Sefton', value : 'E08000014' },
  {text : 'Sheffield', value : 'E08000019' },
  {text : 'Solihull', value : 'E08000029' },
  {text : 'South Tyneside', value : 'E08000023' },
  {text : 'St Helens', value : 'E08000013' },
  {text : 'Stockport', value : 'E08000007' },
  {text : 'Trafford', value : 'E08000009' },
  {text : 'Wakefield', value : 'E08000036' },
  {text : 'Manchester', value : 'E08000003' },
  {text : 'Walsall', value : 'E08000030' },
  {text : 'Wigan', value : 'E08000010' },
  {text : 'Wirral', value : 'E08000015' },
  {text : 'Wolverhampton', value : 'E08000031' },
  {text : 'North Tyneside', value : 'E08000022' },
  {text : 'Wychavon', value : 'E07000238' },
  {text : 'Wyre', value : 'E07000128' },
  {text : 'Wyre Forest', value : 'E07000239' },
  {text : 'Scarborough', value : 'E07000168' },
  {text : 'Sedgemoor', value : 'E07000188' },
  {text : 'Selby', value : 'E07000169' },
  {text : 'Sevenoaks', value : 'E07000111' },
  {text : 'Folkestone and Hythe', value : 'E07000112' },
  {text : 'South Cambridgeshire', value : 'E07000012' },
  {text : 'South Derbyshire', value : 'E07000039' },
  {text : 'South Hams', value : 'E07000044' },
  {text : 'South Holland', value : 'E07000140' },
  {text : 'South Kesteven', value : 'E07000141' },
  {text : 'South Lakeland', value : 'E07000031' },
  {text : 'South Norfolk', value : 'E07000149' },
  {text : 'South Oxfordshire', value : 'E07000179' },
  {text : 'South Ribble', value : 'E07000126' },
  {text : 'South Staffordshire', value : 'E07000196' },
  {text : 'Spelthorne', value : 'E07000213' },
  {text : 'St Albans', value : 'E07000240' },
  {text : 'Stafford', value : 'E07000197' },
  {text : 'Staffordshire Moorlands', value : 'E07000198' },
  {text : 'Tunbridge Wells', value : 'E07000116' },
  {text : 'Uttlesford', value : 'E07000077' },
  {text : 'Vale of White Horse', value : 'E07000180' },
  {text : 'Oxford', value : 'E07000178' },
  {text : 'Pendle', value : 'E07000122' },
  {text : 'Preston', value : 'E07000123' },
  {text : 'West Suffolk', value : 'E07000245' },
  {text : 'Somerset West and Taunton', value : 'E07000246' },
  {text : 'Gedling', value : 'E07000173' },
  {text : 'Gosport', value : 'E07000088' },
  {text : 'Cotswold', value : 'E07000079' },
  {text : 'East Suffolk', value : 'E07000244' },
  {text : 'Ashford', value : 'E07000105' },
  {text : 'Stevenage', value : 'E07000243' },
  {text : 'Nuneaton and Bedworth', value : 'E07000219' },
  {text : 'South Somerset', value : 'E07000189' },
  {text : 'Warwick', value : 'E07000222' },
  {text : 'Watford', value : 'E07000103' },
  {text : 'Waverley', value : 'E07000216' },
  {text : 'Wealden', value : 'E07000065' },
  {text : 'Welwyn Hatfield', value : 'E07000241' },
  {text : 'West Devon', value : 'E07000047' },
  {text : 'West Lancashire', value : 'E07000127' },
  {text : 'West Lindsey', value : 'E07000142' },
  {text : 'West Oxfordshire', value : 'E07000181' },
  {text : 'Winchester', value : 'E07000094' },
  {text : 'Woking', value : 'E07000217' },
  {text : 'Worcester', value : 'E07000237' },
  {text : 'North Norfolk', value : 'E07000147' },
  {text : 'North Warwickshire', value : 'E07000218' },
  {text : 'North West Leicestershire', value : 'E07000134' },
  {text : 'Arun', value : 'E07000224' },
  {text : 'Ashfield', value : 'E07000170' },
  {text : 'Babergh', value : 'E07000200' },
  {text : 'Barrow-in-Furness', value : 'E07000027' },
  {text : 'Basildon', value : 'E07000066' },
  {text : 'Basingstoke and Deane', value : 'E07000084' },
  {text : 'Bassetlaw', value : 'E07000171' },
  {text : 'Blaby', value : 'E07000129' },
  {text : 'Carlisle', value : 'E07000028' },
  {text : 'Castle Point', value : 'E07000069' },
  {text : 'Charnwood', value : 'E07000130' },
  {text : 'Chelmsford', value : 'E07000070' },
  {text : 'Cheltenham', value : 'E07000078' },
  {text : 'Cherwell', value : 'E07000177' },
  {text : 'Chesterfield', value : 'E07000034' },
  {text : 'Chichester', value : 'E07000225' },
  {text : 'Chorley', value : 'E07000118' },
  {text : 'Colchester', value : 'E07000071' },
  {text : 'Copeland', value : 'E07000029' },
  {text : 'Craven', value : 'E07000163' },
  {text : 'Crawley', value : 'E07000226' },
  {text : 'Dacorum', value : 'E07000096' },
  {text : 'Dartford', value : 'E07000107' },
  {text : 'Derbyshire Dales', value : 'E07000035' },
  {text : 'Harborough', value : 'E07000131' },
  {text : 'Harlow', value : 'E07000073' },
  {text : 'Harrogate', value : 'E07000165' },
  {text : 'Hart', value : 'E07000089' },
  {text : 'Redditch', value : 'E07000236' },
  {text : 'Reigate and Banstead', value : 'E07000211' },
  {text : 'Ribble Valley', value : 'E07000124' },
  {text : 'Richmondshire', value : 'E07000166' },
  {text : 'Rochford', value : 'E07000075' },
  {text : 'Rossendale', value : 'E07000125' },
  {text : 'Rother', value : 'E07000064' },
  {text : 'Rugby', value : 'E07000220' },
  {text : 'Runnymede', value : 'E07000212' },
  {text : 'Rushcliffe', value : 'E07000176' },
  {text : 'Rushmoor', value : 'E07000092' },
  {text : 'Ryedale', value : 'E07000167' },
  {text : 'Forest of Dean', value : 'E07000080' },
  {text : 'Fylde', value : 'E07000119' },
  {text : 'Gloucester', value : 'E07000081' },
  {text : 'Stratford-on-Avon', value : 'E07000221' },
  {text : 'Stroud', value : 'E07000082' },
  {text : 'Surrey Heath', value : 'E07000214' },
  {text : 'Swale', value : 'E07000113' },
  {text : 'Tamworth', value : 'E07000199' },
  {text : 'Tandridge', value : 'E07000215' },
  {text : 'Teignbridge', value : 'E07000045' },
  {text : 'Tendring', value : 'E07000076' },
  {text : 'Test Valley', value : 'E07000093' },
  {text : 'Tewkesbury', value : 'E07000083' },
  {text : 'Thanet', value : 'E07000114' },
  {text : 'Three Rivers', value : 'E07000102' },
  {text : 'Tonbridge and Malling', value : 'E07000115' },
  {text : 'Torridge', value : 'E07000046' },
  {text : 'North Hertfordshire', value : 'E07000099' },
  {text : 'North Kesteven', value : 'E07000139' },
  {text : 'Newcastle-under-Lyme', value : 'E07000195' },
  {text : 'North Devon', value : 'E07000043' },
  {text : 'Norwich', value : 'E07000148' },
  {text : 'Oadby and Wigston', value : 'E07000135' },
  {text : 'Worthing', value : 'E07000229' },
  {text : 'Lewes', value : 'E07000063' },
  {text : 'Lichfield', value : 'E07000194' },
  {text : 'Lincoln', value : 'E07000138' },
  {text : 'Maidstone', value : 'E07000110' },
  {text : 'Maldon', value : 'E07000074' },
  {text : 'Malvern Hills', value : 'E07000235' },
  {text : 'Mansfield', value : 'E07000174' },
  {text : 'Melton', value : 'E07000133' },
  {text : 'Mendip', value : 'E07000187' },
  {text : 'Bolsover', value : 'E07000033' },
  {text : 'Boston', value : 'E07000136' },
  {text : 'Braintree', value : 'E07000067' },
  {text : 'Breckland', value : 'E07000143' },
  {text : 'Brentwood', value : 'E07000068' },
  {text : 'Broadland', value : 'E07000144' },
  {text : 'Bromsgrove', value : 'E07000234' },
  {text : 'Broxbourne', value : 'E07000095' },
  {text : 'Broxtowe', value : 'E07000172' },
  {text : 'Burnley', value : 'E07000117' },
  {text : 'Cambridge', value : 'E07000008' },
  {text : 'Cannock Chase', value : 'E07000192' },
  {text : 'Canterbury', value : 'E07000106' },
  {text : 'Gravesham', value : 'E07000109' },
  {text : 'Great Yarmouth', value : 'E07000145' },
  {text : 'Guildford', value : 'E07000209' },
  {text : 'Hambleton', value : 'E07000164' },
  {text : 'Hastings', value : 'E07000062' },
  {text : 'Havant', value : 'E07000090' },
  {text : 'Hertsmere', value : 'E07000098' },
  {text : 'High Peak', value : 'E07000037' },
  {text : 'Hinckley and Bosworth', value : 'E07000132' },
  {text : 'Horsham', value : 'E07000227' },
  {text : 'Huntingdonshire', value : 'E07000011' },
  {text : 'Hyndburn', value : 'E07000120' },
  {text : 'Ipswich', value : 'E07000202' },
  {text : 'Dover', value : 'E07000108' },
  {text : 'East Cambridgeshire', value : 'E07000009' },
  {text : 'East Devon', value : 'E07000040' },
  {text : 'East Hampshire', value : 'E07000085' },
  {text : 'East Hertfordshire', value : 'E07000242' },
  {text : 'East Lindsey', value : 'E07000137' },
  {text : 'East Staffordshire', value : 'E07000193' },
  {text : 'Eastbourne', value : 'E07000061' },
  {text : 'Eastleigh', value : 'E07000086' },
  {text : 'Eden', value : 'E07000030' },
  {text : 'Elmbridge', value : 'E07000207' },
  {text : 'Epping Forest', value : 'E07000072' },
  {text : 'Epsom and Ewell', value : 'E07000208' },
  {text : 'Erewash', value : 'E07000036' },
  {text : 'Exeter', value : 'E07000041' },
  {text : 'Fareham', value : 'E07000087' },
  {text : 'Fenland', value : 'E07000010' },
  {text : 'Mid Devon', value : 'E07000042' },
  {text : 'Mid Suffolk', value : 'E07000203' },
  {text : 'Mid Sussex', value : 'E07000228' },
  {text : 'Mole Valley', value : 'E07000210' },
  {text : 'New Forest', value : 'E07000091' },
  {text : 'Newark and Sherwood', value : 'E07000175' },
  {text : 'Kings Lynn and West Norfolk ', value : 'E07000146' },
  {text : 'Lancaster', value : 'E07000121' },
  {text : 'North East Derbyshire', value : 'E07000038' },
  {text : 'Adur', value : 'E07000223' },
  {text : 'Allerdale', value : 'E07000026' },
  {text : 'Amber Valley', value : 'E07000032' },
  {text : 'Powys', value : 'W06000023' },
  //-{text : 'West Northamptonshire', value : 'E06000062' },
  //-{text : 'Bournemouth Christchurch and Poole', value : 'E06000058' },
  //-{text : 'City of Kingston upon Hull', value : 'E06000010' },
  {text : 'Carmarthenshire', value : 'W06000010' },
  //-{text : 'Swindon', value : 'E06000030' },
  //-{text : 'North Northamptonshire', value : 'E06000061' },
  //-{text : 'Medway', value : 'E06000035' },
  //-{text : 'Slough', value : 'E06000039' },
  //-{text : 'Bath and North East Somerset', value : 'E06000022' },
  //-{text : 'Warrington', value : 'E06000007' },
  //-{text : 'West Berkshire', value : 'E06000037' },
  //-{text : 'Wiltshire', value : 'E06000054' },
  //-{text : 'Windsor and Maidenhead', value : 'E06000040' },
  //-{text : 'Wokingham', value : 'E06000041' },
  //-{text : 'North Somerset', value : 'E06000024' },
  //-{text : 'Leicester', value : 'E06000016' },
  //-{text : 'Luton', value : 'E06000032' },
  {text : 'Merthyr Tydfil', value : 'W06000024' },
  //-{text : 'Bracknell Forest', value : 'E06000036' },
  {text : 'Bridgend', value : 'W06000013' },
  //-{text : 'Brighton and Hove', value : 'E06000043' },
  //-{text : 'Buckinghamshire', value : 'E06000060' },
  {text : 'Caerphilly', value : 'W06000018' },
  {text : 'Cardiff', value : 'W06000015' },
  {text : 'Gwynedd', value : 'W06000002' },
  //-{text : 'Halton', value : 'E06000006' },
  //-{text : 'Herefordshire', value : 'E06000019' },
  {text : 'Isle of Anglesey', value : 'W06000001' },
 //- {text : 'Isle of Wight', value : 'E06000046' },
  //-{text : 'Dorset', value : 'E06000059' },
  //-{text : 'East Riding of Yorkshire', value : 'E06000011' },
  //-{text : 'Middlesbrough', value : 'E06000002' },
  //-{text : 'Milton Keynes', value : 'E06000042' },
  {text : 'Monmouthshire', value : 'W06000021' },
  {text : 'Neath Port Talbot', value : 'W06000012' },
  //-{text : 'Bedford', value : 'E06000055' },
  //-{text : 'Blackburn with Darwen', value : 'E06000008' },
  {text : 'Blackpool', value : 'E06000009' },
  {text : 'Blaenau Gwent', value : 'W06000019' },
  //-{text : 'Central Bedfordshire', value : 'E06000056' },
  {text : 'Ceredigion', value : 'W06000008' },
  //-{text : 'Cheshire East', value : 'E06000049' },
  //-{text : 'Cheshire West and Chester', value : 'E06000050' },
  //-{text : 'City of Bristol', value : 'E06000023' },
  //-{text : 'City of Derby', value : 'E06000015' },
  //-{text : 'City of Nottingham', value : 'E06000018' },
  //-{text : 'City of Peterborough', value : 'E06000031' },
  //-{text : 'City of Plymouth', value : 'E06000026' },
  {text : 'Conwy', value : 'W06000003' },
  //-{text : 'Cornwall', value : 'E06000052' },
  //-{text : 'County Durham', value : 'E06000047' },
  //-{text : 'Darlington', value : 'E06000005' },
  {text : 'Denbighshire', value : 'W06000004' },
  //-{text : 'Hartlepool', value : 'E06000001' },
  //-{text : 'Reading', value : 'E06000038' },
  //-{text : 'Redcar and Cleveland', value : 'E06000003' },
  {text : 'Rhondda Cynon Taf', value : 'W06000016' },
  //-{text : 'Rutland', value : 'E06000017' },
  {text : 'Flintshire', value : 'W06000005' },
  //-{text : 'Stockton-on-Tees', value : 'E06000004' },
  //-{text : 'Stoke-on-Trent', value : 'E06000021' },
  {text : 'Swansea', value : 'W06000011' },
  //-{text : 'Telford and Wrekin', value : 'E06000020' },
  //-{text : 'Thurrock', value : 'E06000034' },
  //-{text : 'Torbay', value : 'E06000027' },
  {text : 'Torfaen', value : 'W06000020' },
  //-{text : 'North East Lincolnshire', value : 'E06000012' },
  //-{text : 'North Lincolnshire', value : 'E06000013' },
  {text : 'Newport', value : 'W06000022' },
  //-{text : 'Northumberland', value : 'E06000057' },
  {text : 'Wrexham', value : 'W06000006' },
  //-{text : 'York', value : 'E06000014' },
  //-{text : 'Shropshire', value : 'E06000051' },
  //-{text : 'South Gloucestershire', value : 'E06000025' },
  //-pton', value : 'E06000045' },
  //-{text : 'Southend-on-Sea', value : 'E06000033' },
  {text : 'Vale of Glamorgan', value : 'W06000014' },
  {text : 'Pembrokeshire', value : 'W06000009' },
  //-{text : 'Portsmouth', value : 'E06000044' },
  {text : 'England and Wales', value : 'K04000001' },
  {text : 'East Renfrewshire', value : 'S12000011' },
  {text : 'Falkirk', value : 'S12000014' },
  {text : 'Fife', value : 'S12000047' },
  {text : 'East Dunbartonshire', value : 'S12000045' },
  {text : 'Shetland Islands', value : 'S12000027' },
  {text : 'South Ayrshire', value : 'S12000028' },
  {text : 'South Lanarkshire', value : 'S12000029' },
  {text : 'Stirling', value : 'S12000030' },
  {text : 'Highland', value : 'S12000017' },
  {text : 'Inverclyde', value : 'S12000018' },
  {text : 'Perth and Kinross', value : 'S12000048' },
  {text : 'Scottish Borders', value : 'S12000026' },
  {text : 'Argyll and Bute', value : 'S12000035' },
  {text : 'Angus', value : 'S12000041' },
  {text : 'Aberdeenshire', value : 'S12000034' },
  {text : 'West Lothian', value : 'S12000040' },
  {text : 'Dumfries and Galloway', value : 'S12000006' },
  {text : 'City of Aberdeen', value : 'S12000033' },
  {text : 'City of Dundee', value : 'S12000042' },
  {text : 'City of Edinburgh', value : 'S12000036' },
  {text : 'City of Glasgow', value : 'S12000049' },
  {text : 'Clackmannanshire', value : 'S12000005' },
  {text : 'Renfrewshire', value : 'S12000038' },
  {text : 'North Lanarkshire', value : 'S12000050' },
  {text : 'Orkney Islands', value : 'S12000023' },
  {text : 'East Lothian', value : 'S12000010' },
  {text : 'Midlothian', value : 'S12000019' },
  {text : 'East Ayrshire', value : 'S12000008' },
  {text : 'Moray', value : 'S12000020' },
  {text : 'Na h-Eileanan Siar', value : 'S12000013' },
  {text : 'North Ayrshire', value : 'S12000021' },
  {text : 'West Dunbartonshire', value : 'S12000039' },
  {text : 'Great Britain', value : 'K03000001' },
  {text : 'Causeway Coast and Glens', value : 'N09000004' },
  {text : 'Antrim and Newtownabbey', value : 'N09000001' },
  {text : 'Armagh City Banbridge and Craigavon', value : 'N09000002' },
  {text : 'Belfast', value : 'N09000003' },
  {text : 'Newry Mourne and Down', value : 'N09000010' },
  {text : 'Ards and North Down', value : 'N09000011' },
  {text : 'Lisburn and Castlereagh', value : 'N09000007' },
  {text : 'Mid Ulster', value : 'N09000009' },
  {text : 'Mid and East Antrim', value : 'N09000008' },
  {text : 'Fermanagh and Omagh', value : 'N09000006' },
  {text : 'Derry City and Strabane', value : 'N09000005' }
  
 
  
];

export default locations;
